import HeaderCom from '../../../components/M-header.vue';

export default {
    name: 'adminPerformance',
    components: {
        HeaderCom
    },
    data() {
        return {
            total: 0, // 总条目数
            currentPage: 1, // 当前页
            limit: 10, // 每页显示数量
            loading: false,
            setLoading: false, // 设置合格条件
            savePassLineLoading: false, // 保存合格条件 加载
            formInline: {},
            stateList: [{value: '0', label: '未录入'}, {value: '1', label: '已录入'}],
            subjectList: JSON.parse(sessionStorage.getItem('subjectList')), // 考试科目
            passList: [{value: '0', label: '不合格'}, {value: '1', label: '合格'}],
            examArray: [],
            tabColumns: [
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '准考证号', key: 'examTicketNo', align: 'center', tooltip: true, resizable: true, minWidth: 120},
                {title: '姓名', key: 'regName', align: 'center', width: 80},
                {title: '性别', key: 'gender', align: 'center', width: 70},
                {title: '身份证号', key: 'idNumber', align: 'center', tooltip: true, resizable: true, width: 130},
                {title: '联系电话', key: 'tel', align: 'center', width: 110},
                // { title: '成绩录入状态', key: 'state', align: 'center' },
                {title: '考试成绩', key: 'achievement', align: 'center', width: 120},
                {
                    title: '是否合格', key: 'gradeStatus', align: 'center', width: 120,
                    render: (h, params) => {
                        let tmpStr = "";
                        if (params.row.gradeStatus == 1) {
                            tmpStr = "合格";
                        } else if (params.row.gradeStatus == 0) {
                            tmpStr = "不合格";
                        } else {
                            tmpStr = "";
                        }
                        return h('span', {
                            style: {
                                color: params.row.gradeStatus == 1 ? "#19be6b" : "#ed3f14"
                            }
                        }, tmpStr)
                    }
                },
                {title: '证书编号', key: 'certificateNo', align: 'center', tooltip: true, resizable: true, width: 120},
                {title: '考试科目', key: 'subjectName', align: 'center', tooltip: true, resizable: true, width: 150},
                {title: '考场号', key: 'examRoom', align: 'center', width: 100},
                {title: '座号', key: 'seatNo', align: 'center', width: 50},
                {title: '考试地点', key: 'examAddress', align: 'center', tooltip: true, width: 120},
                {title: '考试开始时间', key: 'examBeginTime', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '考试结束时间', key: 'examEndTime', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '测试级别', key: 'examMajorTitle', align: 'center', width: 100},
                {title: '测试专业', key: 'examMajor', align: 'center', width: 100,tooltip: true},
                {title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 110}
            ],
            tabData: [],
            importModal: false,
            importForm: {},
            multiple: false, // 是否支持多选文件
            scoreModal: false, // 设置合格分数弹框
            editIndex: -1,  // 当前聚焦的输入框的行数
            editScore: '', // 合格分数输入框
            scoreColumn: [
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '考试科目', key: 'subjectName', align: 'center', width: 300},
                {title: '测试级别', key: 'examMajorTitle', align: 'center'},
                {title: '合格分数', slot: 'passScore', align: 'center'},
                {title: '合格比例（合格人数占总人数比%）', slot: 'passRate', align: 'center'},
                {title: '操作', slot: 'action', width: 180, align: 'center'}
            ],
            scoreData: [],
            examTime: {
                shortcuts: [
                    {
                        text: '最近一周',
                        value() {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近一个月',
                        value() {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近三个月',
                        value() {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            return [start, end];
                        }
                    }
                ]
            },
            batchList: [],//批次
        }
    },
    mounted() {
        this.getBatchOptions();
    },
    methods: {
        //批次list  同步方法必须先执行他
        getBatchOptions() {
            this.$manageHttp.getBatchOptions().then(data => {
                if (data.code == '200') {
                    this.batchList = data.data;
                    this.formInline.batchId=this.batchList[0].id;
                    this.getData();
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 清空上传文件列表
        clearFiles(fileName) {
            this.$refs[fileName].clearFiles();
        },
        onSearch() {
            this.currentPage = 1;
            this.getData();
        },
        // 页码改变的回调，返回改变后的页码(当前页)
        sizeChange(val) {
            this.currentPage = val;
            this.getData();
        },
        // 切换每页条数时的回调，返回切换后的每页条数(每页数)
        pageChange(val) {
            this.limit = val;
            this.getData();
        },
        examChange(val) {
            this.examArray[0] = val[0];
            this.examArray[1] = val[1];
        },
        getData() {
            this.loading = true;
            var params = {
                pageNum: this.currentPage,
                pageSize: this.limit,
                orderByColumn: 'a.examTicketNo',
                isAsc: 'asc',
                type: this.formInline.type,
                beginExamTime: this.examArray[0],
                endExamTime: this.examArray[1],
                examMajorTitle: this.formInline.subjectName,
                examRoom: this.formInline.examRoom,
                examAddress: this.formInline.examAddress,
                beginScore: this.formInline.beginScore,
                endScore: this.formInline.endScore,
                gradeStatus: this.formInline.gradeStatus,
                name: this.formInline.name,
                idNumber: this.formInline.idNumber,
                examTicketNo: this.formInline.examTicketNo,
                batchId:this.formInline.batchId
            }
            this.$manageHttp.getExamAchievementList(params).then(data => {
                if (data.code == '200') {
                    this.loading = false;
                    this.tabData = data.rows;
                    this.total = data.total;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                // this.$Message.error(error);
            })
        },
        // 设置合格条件
        setPassChange(flag) {
            if (flag == true) {
                this.selectExamResultSummary();
            } else {
                this.getData();
            }
        },
        selectExamResultSummary() {
            this.setLoading = true;
            var params = {
                orderByColumn: 'aa.no',
                isAsc: 'asc',
            }
            this.$manageHttp.selectExamResultSummary(params).then(data => {
                if (data.code == '200') {
                    this.setLoading = false;
                    this.scoreData = data.data;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 设置合格条件---修改
        handleEdit(row, index) {
            this.editScore = row.passScore;
            this.editIndex = index;
            this.$nextTick(() => { // 双击获取当前焦点
                this.$refs.scoreFocus.focus();
            })
        },
        // 设置合格条件---保存
        handleSave(row, index) {
            if (this.savePassLineLoading == true) {
                console.log("连击");
                return;
            }

            this.savePassLineLoading = true;
            if (this.editScore == '' || this.editScore == 0) {
                this.editScore = row.passScore;
            }
            this.scoreData[index].passScore = this.editScore;
            this.editIndex = -1;
            var params = {
                examMajorTitle: row.examMajorTitle,
                passScore: this.editScore,
            }
            //使用完就清空
            this.editScore=0;
            this.$manageHttp.savePassLine(params).then(data => {
                if (data.code == '200') {
                    this.$Message.success(data.msg);
                    setTimeout(() => {
                        this.selectExamResultSummary();
                    }, 1000)
                } else {
                    this.$Message.error(data.msg);
                }
                this.savePassLineLoading = false;
            }).catch(error => {
                this.savePassLineLoading = false;
                this.$Message.error(error);

            })
        },
        // 发布
        publish() {
            this.$manageHttp.publishResult().then(data => {
                if (data.code == '200') {
                    this.$Message.success(data.msg);
                    setTimeout(() => {
                        this.getData();
                    }, 500)
                } else {
                    // this.$Message.error(data.msg);
                    this.$Message.error({
                        content: data.msg,
                        duration: 100,
                        closable: true
                    })
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 导出筛选名单
        exportList() {
            var params = {
                pageNum: 1,
                pageSize: 10000,
                orderByColumn: 'a.examTicketNo',
                isAsc: 'asc',
                type: this.formInline.type,
                beginExamTime: this.examArray[0],
                endExamTime: this.examArray[1],
                examMajorTitle: this.formInline.subjectName,
                examRoom: this.formInline.examRoom,
                examAddress: this.formInline.examAddress,
                beginScore: this.formInline.beginScore,
                endScore: this.formInline.endScore,
                gradeStatus: this.formInline.gradeStatus,
                name: this.formInline.name,
                idNumber: this.formInline.idNumber,
                examTicketNo: this.formInline.examTicketNo,
                batchId:this.formInline.batchId
            }
            this.$manageHttp.exportExamAchievementList(params).then(data => {
                if (data.code == '200') {
                    this.$manageHttp.download(data.msg);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 生成证书
        createCard() {
            this.$manageHttp.genCertificate().then(data => {
                if (data.code == '200') {
                    this.$Message.success(data.msg);
                    setTimeout(() => {
                        this.getData();
                    }, 500)
                } else {
                    this.$Message.error(data.msg);
                }
            }).then(error => {
                // this.$Message.error(error);
            })
        },
        // 文件超出指定大小限制时的钩子
        handleProgress(event, file, fileList) {
            console.log(file, 'file');
            console.log(fileList, 'fileList');
            if (fileList.length >= 2) {
                this.$Message.error({background: true, content: `只能上传1个文件,当前共选择了 ${fileList.length} 个`});
                return false;
            }
        },
        // 导入成绩---确定
        importSure() {
            this.$Message.success({background: true, content: '批量导入成功！本次共导入101名考生成绩'});
            setTimeout(() => {
                this.importModal = false;
            }, 1500)
        }
    }
}